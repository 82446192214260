import { CreateOrModifyCategoriaDto } from '../dto/create-categoria.dto';
import axios from 'axios';
import { ReturnCategoriaDto } from '../dto/return-categoria.dto';

const URL_CATEGORIA_ROOT = 'transaccion/categoria';

export function updateCategoria(c: CreateOrModifyCategoriaDto) {
  return axios
    .request<ReturnCategoriaDto>({
      method: c.id ? 'put' : 'post',
      url: URL_CATEGORIA_ROOT + (c.id ? `/${c.id}` : ''),
      data: c,
    })
    .then((r) => r.data);
}

export function getAllCategorias() {
  return axios.get<ReturnCategoriaDto[]>(URL_CATEGORIA_ROOT).then((r) => r.data);
}
