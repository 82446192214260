import React from 'react';
import { Spin } from 'antd';

const LoadingPage = () => {
  return (
    <Spin tip='Cargando...' size='large'>
      <div className='content' style={{ padding: '50px' }} />
    </Spin>
  );
};

export default LoadingPage;
