export interface ReturnCategoriaDto {
  id: number;
  categoriaPadreId?: number | null;
  code: string;
  nombre: string;
  posicion: number;
}

export const returnCategoriaEmpty: ReturnCategoriaDto = {
  id: 0,
  code: '',
  nombre: '',
  posicion: 0,
};
