import { useGenericArray } from '../../utils/hooks/generic-array.hook';
import { ReturnTipoTransaccionDto, URL_GET_TIPOS_TRANSACCION } from '../../service/transaccion/dto/return-tipo-transaccion.dto';
import { CreateTipoDto } from '../../service/transaccion/dto/create-tipo.dto';

export function useTipoTransaccion(loggedIn: boolean) {
  const {
    items: tipos,
    cargando,
    addItem,
  } = useGenericArray<ReturnTipoTransaccionDto, CreateTipoDto>(URL_GET_TIPOS_TRANSACCION, loggedIn);

  return {
    tipos,
    cargando,
    addTipoTransaccion: addItem,
  };
}
