import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { GetMeReturnDto, URL_GET_ME } from '../service/user/dto/get-me-return.dto';
import { LoginUserReturnDto, loginUserReturnEmpty, URL_SIGNIN, URL_USER } from '../service/user/dto/login-user-return.dto';
import { toast } from 'react-toastify';
import { CategoriaConRelaciones, useCategoria } from '../service/categoria/hook/categoria.hook';
import { ReturnCategoriaDto, returnCategoriaEmpty } from '../service/categoria/dto/return-categoria.dto';
import { useTipoTransaccion } from '../pages/transacciones/tipo-transaccion.hook';
import { ReturnTipoTransaccionDto, tipoTransaccionEmpty } from '../service/transaccion/dto/return-tipo-transaccion.dto';
import VistaError from '../components/UI/VistaError';
import { errorRandomToIError, IError } from '../service/utils/error-model';
import { notification } from 'antd';
import { IconType } from 'antd/es/notification/interface';
import { CreateTipoDto } from '../service/transaccion/dto/create-tipo.dto';
import { CreateOrModifyCategoriaDto } from '../service/categoria/dto/create-categoria.dto';
import { CreateUserDto } from '../service/user/dto/create-user.dto';

interface AuthValues {
  loggedIn: boolean;
  logOut: () => void;
  loginLoaded: boolean;
  signIn: (user: string, password: string) => Promise<boolean>;
  user?: GetMeReturnDto;
  categorias: ReturnCategoriaDto[];
  categoriasArbol: CategoriaConRelaciones;
  tipos: ReturnTipoTransaccionDto[];
  setError: (e?: IError) => void;
  errorView?: React.ReactElement;
  setErrorException: (e: any) => void;
  sendNotification: (message: string, type?: IconType, description?: string) => void;
  addTipoTransaccion: (i: CreateTipoDto) => Promise<ReturnTipoTransaccionDto>;
  addOrCreateCategoria: (i: CreateOrModifyCategoriaDto | CreateOrModifyCategoriaDto[]) => Promise<ReturnCategoriaDto[]>;
  moverCategoria: (categoriaId: number, padreId: number | null, posicionRelativa: number) => Promise<ReturnCategoriaDto[]>;
  registerUser: (i: CreateUserDto) => Promise<LoginUserReturnDto>;
}

const AuthContext = React.createContext<AuthValues>({
  logOut: () => {},
  loggedIn: false,
  loginLoaded: false,
  signIn: () => Promise.resolve(false),
  user: undefined,
  categorias: [],
  categoriasArbol: { ...returnCategoriaEmpty, hijos: [] },
  tipos: [],
  setError: () => {},
  setErrorException: () => {},
  sendNotification: () => {},
  addTipoTransaccion: () => Promise.resolve(tipoTransaccionEmpty),
  addOrCreateCategoria: () => Promise.resolve([]),
  moverCategoria: () => Promise.resolve([]),
  registerUser: () => Promise.resolve(loginUserReturnEmpty),
});

const saveTokenToLocalStorage = (token: string | undefined) => {
  if (token) {
    localStorage.setItem('token', token);
  } else {
    localStorage.removeItem('token');
  }
};

function getTokenFromLocalStorage() {
  const token = localStorage.getItem('token');
  return token ? token : undefined;
}

function AuthProvider({ children }: { children: ReactNode }) {
  const [api, contextHolder] = notification.useNotification();
  const [loginLoaded, setLoginLoaded] = useState(false);
  const [token, setToken] = useState<string | undefined>(getTokenFromLocalStorage);
  const [user, setUser] = useState<GetMeReturnDto | undefined>(undefined);
  const logOut = useCallback(() => {
    //para desloguear
    setUser(undefined);
    setToken(undefined);
    saveTokenToLocalStorage(undefined);
  }, []);
  const loggedIn = useMemo(() => !!user, [user]);

  useEffect(() => {
    setLoginLoaded(false);
    if (token) {
      //Hay un token establecido nuevo
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios
        .get<GetMeReturnDto>(URL_GET_ME)
        .then(({ data }) => {
          setUser(data);
          setLoginLoaded(true);
        })
        .catch(() => {
          /** Fallo en el logueo*/
          logOut();
          setLoginLoaded(true);
        });
    } else {
      //El token se quito
      axios.defaults.headers.common['Authorization'] = '';
      logOut();
      setLoginLoaded(true);
    }
  }, [logOut, token]);
  // useEffect(() => console.log('cambio logout: ' + logOut),[logOut])
  const signIn = useCallback(
    (usuario: string, contrasena: string): Promise<boolean> =>
      axios
        .post<LoginUserReturnDto>(URL_SIGNIN, { usuario, contrasena })
        .then(({ data }) => {
          saveTokenToLocalStorage(data.token);
          setToken(data.token);
          setUser({
            usuario: data.usuario,
            apellido: data.apellido,
            nombre: data.nombre,
          });
          return true;
        })
        .catch((e) => {
          //mostrar notifiacion de error
          toast.error(e?.response?.data?.message ?? e?.message ?? 'Error en logueo');
          throw e;
        }),
    [],
  );

  const { categorias, categoriasArbol, addOrCreateCategoria, moverCategoria } = useCategoria(loggedIn);
  const { tipos, addTipoTransaccion } = useTipoTransaccion(loggedIn);
  const [error, setError] = useState<IError | undefined>();
  const setErrorException = useCallback((error: any) => {
    setError(errorRandomToIError(error));
  }, []);
  const errorView = useMemo(() => {
    return error ? <VistaError error={error} /> : undefined;
  }, [error]);

  const sendNotification = useCallback(
    (message: string, type: IconType = 'info', description: string | undefined = undefined) => {
      api.open({
        type: type,
        message: message,
        placement: 'topRight',
        description: description,
      });
    },
    [api],
  );

  const registerUser = useCallback((u: CreateUserDto) => {
    return axios
      .post<LoginUserReturnDto>(URL_USER, u)
      .then(({ data }) => {
        saveTokenToLocalStorage(data.token);
        setToken(data.token);
        setUser({
          usuario: data.usuario,
          apellido: data.apellido,
          nombre: data.nombre,
        });
        return data;
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message ?? e?.message ?? 'Error en logueo');
        throw e;
      });
  }, []);

  const value: AuthValues = {
    loggedIn,
    logOut,
    loginLoaded,
    signIn,
    user,
    categorias,
    categoriasArbol,
    tipos,
    setError,
    errorView,
    setErrorException,
    sendNotification,
    addTipoTransaccion,
    addOrCreateCategoria,
    moverCategoria,
    registerUser,
  };
  return (
    <AuthContext.Provider value={value}>
      {contextHolder}
      <>{children}</>
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
