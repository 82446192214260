export const URL_SIGNIN = 'user/login';
export const URL_USER = 'user';

export interface LoginUserReturnDto {
  usuario: string;

  nombre: string;

  apellido: string;

  token: string;

  expiracionToken: string;
}

export const loginUserReturnEmpty: LoginUserReturnDto = {
  apellido: '',
  expiracionToken: '',
  nombre: '',
  token: '',
  usuario: '',
};
