import { useCallback, useEffect, useMemo, useState } from 'react';
import { ReturnCategoriaDto, returnCategoriaEmpty } from '../dto/return-categoria.dto';
import { CreateOrModifyCategoriaDto } from '../dto/create-categoria.dto';
import { getAllCategorias, updateCategoria as updateCategoriaApi } from '../api/categoria.api';

export type CategoriaConRelaciones = ReturnCategoriaDto & {
  padre?: CategoriaConRelaciones;
  hijos: CategoriaConRelaciones[];
};

export function ordenadorDeCategorias(a: { posicion: number; nombre: string }, b: { posicion: number; nombre: string }) {
  if (a.posicion === b.posicion) {
    return a.nombre.localeCompare(b.nombre);
  }
  return a.posicion - b.posicion;
}

// Función para ordenar recursivamente el árbol
function ordenarArbolPorPosicion(arbol: CategoriaConRelaciones): CategoriaConRelaciones {
  // Ordenar los hijos del nodo actual por 'posicion', y por 'nombre' si las posiciones son iguales
  arbol.hijos.sort(ordenadorDeCategorias);

  // Llamar recursivamente a esta función para cada hijo
  arbol.hijos.forEach((hijo) => ordenarArbolPorPosicion(hijo));

  return arbol;
}

export function busquedaCategoriaConPadre(
  arbol: CategoriaConRelaciones,
  id: number,
  idPadre: number,
): CategoriaConRelaciones | null {
  // Función auxiliar para buscar la categoría padre
  function buscarCategoria(arbol: CategoriaConRelaciones, id: number): CategoriaConRelaciones | null {
    for (const categoria of arbol.hijos) {
      if (categoria.id === id) {
        return categoria;
      }
      const resultado = buscarCategoria(categoria, id);
      if (resultado) {
        return resultado;
      }
    }
    return null;
  }

  // Función auxiliar para verificar si una categoría contiene a otra
  function contieneCategoria(categoria: CategoriaConRelaciones, id: number): boolean {
    for (const hijo of categoria.hijos) {
      if (hijo.id === id || contieneCategoria(hijo, id)) {
        return true;
      }
    }
    return false;
  }

  const categoriaPadre = buscarCategoria(arbol, idPadre);
  if (categoriaPadre) {
    if (categoriaPadre.id === id) {
      return categoriaPadre;
    } else if (contieneCategoria(categoriaPadre, id)) {
      return buscarCategoria(categoriaPadre, id);
    }
  }
  return null;
}

export function useCategoria(loggedIn: boolean) {
  const [categorias, setCategorias] = useState<ReturnCategoriaDto[]>([]);
  const [cargando, setCargando] = useState(false);
  useEffect(() => {
    let carga = true;
    setCargando(true);
    setCategorias([]);
    if (loggedIn) {
      getAllCategorias()
        .then((r) => {
          carga && setCategorias(r);
        })
        .catch((_) => {
          carga && setCategorias([]);
        })
        .finally(() => carga && setCargando(false));
    } else {
      setCargando(false);
      setCategorias([]);
    }
  }, [loggedIn]);
  const categoriasArbol: CategoriaConRelaciones = useMemo(() => {
    const categoriasConRelaciones: CategoriaConRelaciones[] = categorias.map((c) => ({
      ...c,
      hijos: [],
      padre: undefined,
    }));
    const arbol: CategoriaConRelaciones = { ...returnCategoriaEmpty, hijos: [] };
    const categoriasPorId = new Map<number, CategoriaConRelaciones>();
    categoriasConRelaciones.forEach((categoria) => {
      categoriasPorId.set(categoria.id, categoria);
    });
    categoriasConRelaciones.forEach((categoria) => {
      if (categoria.categoriaPadreId) {
        const padre = categoriasPorId.get(categoria.categoriaPadreId);
        padre?.hijos?.push(categoria);
      } else {
        arbol.hijos.push(categoria);
      }
    });
    return ordenarArbolPorPosicion(arbol);
  }, [categorias]);

  const addOrCreateCategoria = useCallback(
    (categoriasInput: CreateOrModifyCategoriaDto | CreateOrModifyCategoriaDto[]): Promise<ReturnCategoriaDto[]> => {
      // Convierte la entrada en un array si no lo es
      const categoriasArray = Array.isArray(categoriasInput) ? categoriasInput : [categoriasInput];

      // Crea un array de promesas para actualizar cada categoría
      const updatePromises = categoriasArray.map((categoria) => updateCategoriaApi(categoria));

      return Promise.all(updatePromises).then((responses) => {
        setCategorias((categorias) => {
          // Copia el estado actual de categorías
          let updatedCategorias = [...categorias];

          // Itera sobre cada respuesta
          responses.forEach((r) => {
            // Verifica si ya existe un elemento con el mismo id
            const exists = updatedCategorias.some((categoria) => categoria.id === r.id);
            if (exists) {
              // Si existe, actualiza el elemento
              updatedCategorias = updatedCategorias.map((categoria) => (categoria.id === r.id ? r : categoria));
            } else {
              // Si no existe, agrega el nuevo elemento al final
              updatedCategorias = [...updatedCategorias, r];
            }
          });

          return updatedCategorias;
        });

        return responses;
      });
    },
    [],
  );

  const moverCategoria = useCallback(
    (categoriaId: number, padreId: number | null, posicionRelativa: number) => {
      //lista de heramanos ordenado de la categoria a moverse
      const categoriasHermanas: (ReturnCategoriaDto & { modificado: boolean })[] = categorias
        .filter((c) => !(c.id === categoriaId) && c.categoriaPadreId === padreId)
        .sort(ordenadorDeCategorias)
        .map((r) => ({ ...r, modificado: false }));
      const categoriaMoviendo = categorias.find((c) => c.id === categoriaId)!;
      categoriasHermanas.splice(posicionRelativa, 0, {
        ...categoriaMoviendo,
        posicion: categoriaMoviendo.posicion,
        categoriaPadreId: categoriaMoviendo.categoriaPadreId !== padreId ? padreId : undefined,
        modificado: categoriaMoviendo.categoriaPadreId !== padreId,
      });
      const crearEspacios = (comprobarDesde: number) => {
        for (let i = comprobarDesde; i < categoriasHermanas.length - 1; i++) {
          const izquierda = i > 0 ? categoriasHermanas[i - 1] : null;
          const primero = categoriasHermanas[i];
          const segundo = categoriasHermanas[i + 1];
          const derecha = i < categoriasHermanas.length - 2 ? categoriasHermanas[i + 2] : null;
          if (ordenadorDeCategorias(primero, segundo) > 0) {
            //Esta mal ordenado
            if (!derecha) {
              segundo.posicion = primero.posicion + 1000;
              segundo.modificado = true;
            } else {
              let puntoMedio: number | null = null;
              do {
                puntoMedio =
                  (izquierda?.posicion ?? 0) + 1 < segundo.posicion
                    ? Math.floor(((izquierda?.posicion ?? 0) + segundo.posicion) / 2)
                    : null;
                if (puntoMedio !== null) {
                  primero.posicion = puntoMedio;
                  primero.modificado = true;
                } else {
                  primero.posicion = (izquierda?.posicion ?? 0) + 1;
                  primero.modificado = true;
                  segundo.posicion = (izquierda?.posicion ?? 0) + 2;
                  segundo.modificado = true;
                  crearEspacios(i + 1);
                }
              } while (puntoMedio === null);
            }
          }
          if (primero.modificado) {
            //si el primero ya estaba modificado, tratar de volver a modificar para que este en el medio
            primero.posicion = Math.floor(((izquierda?.posicion ?? 0) + segundo.posicion) / 2);
          }
        }
      };
      crearEspacios(0);
      return addOrCreateCategoria(
        categoriasHermanas
          .filter((r) => r.modificado)
          .map((r) => ({
            id: r.id,
            posicion: r.posicion,
            categoriaPadreId: r.id === categoriaId ? r.categoriaPadreId : undefined,
          })),
      );
    },
    [addOrCreateCategoria, categorias],
  );

  return {
    categoriasArbol,
    categorias,
    cargando,
    addOrCreateCategoria,
    moverCategoria,
  };
}

export const useCategoriaRecursivo = (categoriasId: number[], categoriasArbol: CategoriaConRelaciones) =>
  useMemo(() => {
    // Función recursiva para obtener todos los IDs de categorías hijas
    const obtenerCategoriasHijas = (categoria: CategoriaConRelaciones, resultado: number[] = []) => {
      resultado.push(categoria.id);
      if (categoria.hijos && categoria.hijos.length > 0) {
        categoria.hijos.forEach((hijo) => obtenerCategoriasHijas(hijo, resultado));
      }
    };
    // Función recursiva para encontrar una categoría en todx el árbol
    const buscarCategoriaPorId = (categoria: CategoriaConRelaciones, id: number): CategoriaConRelaciones | null => {
      if (categoria.id === id) {
        return categoria;
      }
      for (const hijo of categoria.hijos) {
        const encontrada = buscarCategoriaPorId(hijo, id);
        if (encontrada) {
          return encontrada;
        }
      }
      return null;
    };
    const recorrerArbol = (arbol: CategoriaConRelaciones[]) => {
      arbol.forEach((categoria) => {
        categoriasId.forEach((id) => {
          const categoriaEncontrada = buscarCategoriaPorId(categoria, id);
          if (categoriaEncontrada) {
            obtenerCategoriasHijas(categoriaEncontrada, resultado);
          }
        });
      });
    };
    const resultado: number[] = [];
    recorrerArbol(categoriasArbol.hijos);
    return resultado;
  }, [categoriasArbol, categoriasId]);
