import React, { useContext, useMemo } from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import loadable from '@loadable/component';
import { AuthContext } from './context/AuthProvider';
import LoadingPage from './components/Loading';

const LOGIN_PAGE = '/login';
export const REGISTER_PAGE = '/register';
const HOME_PAGE = '/';

export interface TipoRuta {
  nombre: string;
  link: string;
  import?: { (): Promise<any> };
  hijos?: TipoRuta[];
  protected: boolean; //esta protegido del acceso sin loguearse
  ocultarOpcion?: boolean; // le decimos si queremos que esta ruta este oculta del menu
  redirectOnLoggedIn?: string; //le decimos a donde redirigir si el usuario esta logueado
}

export const itemsRutas: TipoRuta[] = [
  {
    nombre: 'Inicio',
    link: HOME_PAGE,
    protected: true,
    import: () => import('./pages/Index/Index'),
  },
  {
    nombre: 'Transaccion',
    link: '/transaccion',
    protected: true,
    hijos: [
      {
        nombre: 'Transacciones Realizadas',
        link: '/realizada',
        import: () => import('./pages/transacciones/Transacciones'),
        protected: true,
      },
      {
        nombre: 'Estadisticas',
        link: '/estadisticas',
        import: () => import('./pages/transacciones/Graficos'),
        protected: true,
      },
      {
        nombre: 'Recurrentes',
        link: '/recurrente',
        import: () => import('./pages/transacciones/TransaccionesRecurrentesTabla'),
        protected: true,
        hijos: [
          {
            nombre: 'Recurrentes',
            link: '/:id',
            import: () => import('./pages/transacciones/TransaccionesRecurrentesDetalle'),
            protected: true,
            ocultarOpcion: true,
          },
        ],
      },
      {
        nombre: 'Categorias',
        link: '/categorias',
        import: () => import('./pages/categoria/AdministracionCategorias'),
        protected: true,
      },
    ],
  },
  {
    nombre: 'Combustible',
    link: '/combustible',
    protected: true,
    hijos: [
      {
        nombre: 'Cargas',
        link: '/cargas',
        import: () => import('./pages/combustible/ListaCargaCombustible'),
        protected: true,
      },
      {
        nombre: 'Viajes',
        link: '/viajes',
        import: () => import('./pages/combustible/ListaDeViajes'),
        protected: true,
      },
      {
        nombre: 'Configuracion',
        link: '/configuracion',
        protected: true,
        hijos: [
          {
            nombre: 'Categorias',
            link: '/categoria',
            import: () => import('./pages/combustible/configuracion/categoria/CargaCategoria'),
            protected: true,
          },
        ],
      },
    ],
  },
  {
    nombre: 'Bolt',
    link: '/bolt',
    protected: true,
    hijos: [
      {
        nombre: 'Lista de viajes',
        link: '/lista',
        protected: true,
        import: () => import('./pages/Bolt/Lista/ListaViajesBolt'),
      },
      {
        nombre: 'Estadisticas',
        link: '/estadisticas',
        protected: true,
        import: () => import('./pages/Bolt/estadistica/estadistica'),
      },
      {
        nombre: 'Configuracion',
        link: '/configuracion',
        protected: true,
        hijos: [
          {
            nombre: 'Credencial Activa',
            link: '/credencial',
            protected: true,
            import: () => import('./pages/Bolt/credencial/CredencialStatus'),
          },
          {
            nombre: 'Categorias',
            link: '/categoria',
            protected: true,
            import: () => import('./pages/Bolt/configuracion/categoria/BoltConfiguracionCategoria'),
          },
        ],
      },
    ],
  },
  {
    nombre: 'Inicio Sesion',
    link: LOGIN_PAGE,
    import: () => import('./pages/Login/SignIn'),
    protected: false,
    ocultarOpcion: true,
    redirectOnLoggedIn: HOME_PAGE,
  },
  {
    nombre: 'Crear usuario',
    link: REGISTER_PAGE,
    import: () => import('./pages/Login/Register'),
    protected: false,
    ocultarOpcion: true,
    redirectOnLoggedIn: HOME_PAGE,
  },
  {
    nombre: 'Pagina No Encontrada',
    link: '*',
    import: () => import('./pages/404/404'),
    protected: false,
    ocultarOpcion: true,
  },
];

export function MiRuta() {
  const { loggedIn } = useContext(AuthContext);

  const rutas = useMemo(() => {
    const rutasDesplegadas: RouteObject[] = [];
    const funcionHijas = (basePath: string, r: TipoRuta) => {
      if (r.import) {
        /** Redirecciona a Login si es una ruta protegida y si no esta logueado, si esta logueado y si esta activa la redireccion, redirecciona tambien*/
        const protegido = r.protected && !loggedIn;
        const redirect = protegido ? LOGIN_PAGE : loggedIn && r.redirectOnLoggedIn ? r.redirectOnLoggedIn : null;
        let OtherComponent;
        OtherComponent = loadable(r.import, { fallback: <LoadingPage /> });
        rutasDesplegadas.push({
          path: basePath + r.link,
          element: redirect ? <Navigate to={redirect} /> : <OtherComponent />,
        });
      }
      r.hijos?.forEach((i) => funcionHijas(basePath + r.link, i));
    };
    itemsRutas.forEach((i) => funcionHijas('', i));
    return rutasDesplegadas;
  }, [loggedIn]);

  return useRoutes(rutas);
}
