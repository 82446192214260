import { TipoTransaccionEnum } from './tipo-transaccion-enum.enum';

export const URL_GET_TIPOS_TRANSACCION = 'transaccion/tipo';

export interface ReturnTipoTransaccionDto {
  id: number;
  code: string;
  descripcion: string;
  tipo: TipoTransaccionEnum;
}
export const tipoTransaccionEmpty: ReturnTipoTransaccionDto = {
  tipo: TipoTransaccionEnum.EGRESO,
  code: '',
  id: 0,
  descripcion: '',
};
