import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';

export function useGenericArray<GetModel, SaveModel>(url: string, loggedIn: boolean) {
  const [items, setItems] = useState<GetModel[]>([]);
  const [cargando, setCargando] = useState(true);
  useEffect(() => {
    setCargando(true);
    setItems([]);
    if (loggedIn) {
      axios
        .get<GetModel[]>(url)
        .then((response) => {
          setItems(response.data);
        })
        .catch((_) => {
          setItems([]);
        })
        .finally(() => {
          setCargando(false);
        });
    }
  }, [url, loggedIn]);
  const addItem = useCallback(
    (item: SaveModel): Promise<GetModel> => {
      return axios.post<GetModel>(url, item).then((response) => {
        setItems((i) => [...i, response.data]);
        return response.data;
      });
    },
    [url],
  );
  return {
    items,
    cargando,
    addItem,
  };
}
