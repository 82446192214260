import React, { useContext, useMemo, useState } from 'react';
import { AuthContext } from './context/AuthProvider';
import { itemsRutas, MiRuta, TipoRuta } from './rutas';
import { Link, useLocation } from 'react-router-dom';
import { AiFillCaretDown } from 'react-icons/ai';

import { Breadcrumb, Button, Dropdown, Layout, Menu, Modal, Spin } from 'antd';
import { ItemType } from 'antd/es/menu/interface';
import { useWindowSize } from './utils/windowsSize';

const { Header, Content, Footer, Sider } = Layout;

function useMenuSelected(pathname: string, menus: TipoRuta[]) {
  const menuSelected = useMemo(() => {
    const pathDir = pathname.split('/').filter((r) => r);
    const salida: string[] = [];
    let menuActual = menus;
    pathDir.forEach((p) => {
      const menuEncontrado = menuActual.find((m) => m.link === '/' + p);
      if (!menuEncontrado) {
        salida.push(p);
        menuActual = [];
      } else {
        salida.push(menuEncontrado.nombre);
        menuActual = menuEncontrado.hijos || [];
      }
    });
    return salida;
  }, [menus, pathname]);
  return {
    menuSelected,
  };
}

function App() {
  const { loginLoaded, loggedIn, logOut, user, errorView, setError } = useContext(AuthContext);
  const location = useLocation();
  const { pathname } = location;
  const items = useMemo<ItemType[]>(() => {
    const funcionHijas = (basePath: string, m: TipoRuta): ItemType => {
      const urlActual = basePath + m.link;
      const children = m.hijos ? m.hijos.filter((i) => !i.ocultarOpcion).map((i) => funcionHijas(urlActual, i)) : [];
      return {
        label: !children.length ? <Link to={{ pathname: urlActual }}>{m.nombre}</Link> : m.nombre,
        key: urlActual,
        disabled: false,
        children: children.length ? children : undefined,
      };
    };
    return itemsRutas.filter((m) => !m.ocultarOpcion).map((i) => funcionHijas('', i));
  }, []);
  const itemsMenuUsuario = useMemo<ItemType[]>(
    () => [
      {
        label: (
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://www.antgroup.com'
            onClick={(e) => {
              e.preventDefault();
              logOut();
            }}
          >
            Cerrar Sesion
          </a>
        ),
        key: 'cerrar-sesion',
      },
    ],
    [logOut],
  );
  const size = useWindowSize();
  const { menuSelected } = useMenuSelected(pathname, itemsRutas);
  const [collapsed, setCollapsed] = useState(false);
  return (
    <Layout hasSider>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        breakpoint='lg'
        collapsedWidth='0'
        style={{
          height: '100vh',
          position: 'fixed',
          insetInlineStart: 0,
          top: 0,
          bottom: 0,
          scrollbarWidth: 'thin',
          scrollbarColor: 'unset',
          zIndex: 100,
        }}
      >
        <Menu theme='dark' selectedKeys={[pathname]} mode='inline' items={items} />
      </Sider>
      <Layout style={{ marginInlineStart: collapsed ? undefined : 200 }}>
        <Header>
          {loggedIn && user && (
            <Dropdown menu={{ items: itemsMenuUsuario }}>
              <a href={'no'} className='ant-dropdown-link' onClick={(e) => e.preventDefault()} style={{ float: 'right' }}>
                {user.usuario}
                <AiFillCaretDown />
              </a>
            </Dropdown>
          )}
        </Header>
        <Content style={{ padding: size.width && size.width < 600 ? '0 0px' : '0 50px' }}>
          <Breadcrumb
            style={{ margin: '16px 0' }}
            items={[{ key: 'inicio', title: 'Inicio' }].concat(menuSelected.map((m) => ({ key: m, title: m })))}
          />
          <Spin spinning={!loginLoaded}>
            <div className='site-layout-content'>{loginLoaded && <MiRuta />}</div>
          </Spin>
        </Content>
        <Footer style={{ textAlign: 'center' }}>Flujo Financiero ©2024 Created by javierovico@gmail.com</Footer>
      </Layout>
      {/*<Header>*/}
      {/*  {loggedIn && user && (*/}
      {/*    <Dropdown menu={{ items: itemsMenuUsuario }}>*/}
      {/*      <a href={'no'} className='ant-dropdown-link' onClick={(e) => e.preventDefault()} style={{ float: 'right' }}>*/}
      {/*        {user.usuario}*/}
      {/*        <AiFillCaretDown />*/}
      {/*      </a>*/}
      {/*    </Dropdown>*/}
      {/*  )}*/}
      {/*</Header>*/}
      {/*<Layout>*/}
      {/*  <Sider*/}
      {/*    collapsible*/}
      {/*    collapsed={collapsed}*/}
      {/*    onCollapse={(value) => setCollapsed(value)}*/}
      {/*    breakpoint='lg'*/}
      {/*    collapsedWidth='0'*/}
      {/*    style={{ position: 'fixed', height: '100%', zIndex: 10 }}*/}
      {/*  >*/}
      {/*    <Menu theme='dark' selectedKeys={[pathname]} mode='inline' items={items} />*/}
      {/*  </Sider>*/}
      {/*  <Layout>*/}
      {/*    <Content style={{ padding: size.width && size.width < 600 ? '0 0px' : '0 50px' }}>*/}
      {/*      <Breadcrumb*/}
      {/*        style={{ margin: '16px 0' }}*/}
      {/*        items={[{ key: 'inicio', title: 'Inicio' }].concat(menuSelected.map((m) => ({ key: m, title: m })))}*/}
      {/*      />*/}
      {/*      <Spin spinning={!loginLoaded}>*/}
      {/*        <div className='site-layout-content'>{loginLoaded && <MiRuta />}</div>*/}
      {/*      </Spin>*/}
      {/*    </Content>*/}
      {/*    <Footer style={{ textAlign: 'center' }}>Flujo Financiero ©2024 Created by javierovico@gmail.com</Footer>*/}
      {/*  </Layout>*/}
      {/*</Layout>*/}

      <Modal
        zIndex={9000}
        title={'Error'}
        open={!!errorView}
        onOk={() => setError()}
        onCancel={() => setError()}
        footer={[
          <Button key='1' type='primary' onClick={() => setError()}>
            Cerrar
          </Button>,
        ]}
      >
        {errorView}
      </Modal>
    </Layout>
  );
}

export default App;
